import React, { useState, useMemo } from "react";
import * as styles from "./index.module.scss";
import Layout from "components/layout";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import SuperbowlTile from "components/superbowl_tile";
import SignUpButton from "components/super_bowl_sign_up_button";
import ReportSignUpButton from "components/super_bowl_request_report";
import * as box from "styles/box.module.scss"

import AiringItem from "components/airing_item";
import {getCloudFrontImageUrl} from "utils/helpers";
import InterruptorBar from "components/interruptor_bar";

const SuperBowlPage = ({ data  }) => {
  // const wpPosts = data.allWpPost.nodes;
  // const adRankingsSection = useMemo(() => {
  //   return wpPosts.find((post) => post.id == "cG9zdDoxNTI3");
  // },[wpPosts]);
  // const shareOfSearchSection = useMemo(() => {
  //   return wpPosts.find((post) => post.id == "cG9zdDoxNTMw");
  // }, [wpPosts])

  const tiles = data?.allGoogleSpreadsheetTopSuperBowl2023?.edges ? data.allGoogleSpreadsheetTopSuperBowl2023.edges.map((node) => node.node)?.sort((a,b) => Number(a?.rank) - Number(b?.rank)) : [];
  const airings = data.allGoogleSpreadsheetAllSuperBowl2023?.edges ? data.allGoogleSpreadsheetAllSuperBowl2023.edges.map((node) => node.node)?.sort((a,b) => Number(a?.rank) - Number(b?.rank)) : [];
  return (
    <Layout pageTitle={"Resources"}
      interruptorBar={<InterruptorBar
        to={"https://topads.edo.com/resources/superbowl-lvii-celebrities"}
        label={"See EDO's ranking of every celebrity within Super Bowl LVII."}
      />}
    >
        <div className={classNames(styles.outerContainer,styles.text)}>
            <div className={classNames(styles.container, styles.mainContainer)}>
                <div className={classNames(styles.headerContainer)}>
                    <div className={classNames("uk-flex uk-flex-center uk-flex-middle uk-flex-row")}>
                        <div>
                            <img className={styles.logo} src={getCloudFrontImageUrl("homepage/Super_Bowl_LVII_logo.png")} alt={"superbowl logo"} />
                        </div>
                        <div className={classNames(styles.titleContainer, "uk-flex-column uk-text-left")}>
                            <div className={classNames(styles.title)}>
                                Super Bowl LVII Ad Rankings
                            </div>
                            <div className={classNames(styles.subTitle)}>
                                February 12, 2023
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classNames(styles.tileContainer, styles.elementContainer)}>
                    <SuperbowlTile url={tiles?.[0]?.desktopImage} creativeId={tiles?.[0]?.creativeId}/>
                    <div className={classNames("uk-flex uk-flex-row", styles.tileRow)}>
                        {
                            [1, 2].map((i) => (
                                <div className={classNames("uk-width-1-2")}>
                                    <SuperbowlTile url={tiles?.[i]?.desktopImage} creativeId={tiles?.[i]?.creativeId}/>
                                </div>
                                )
                            )
                        }
                    </div>
                    <div className={classNames("uk-flex uk-flex-row", styles.tileRow)}>
                        {
                            [3, 4].map((i) => (
                                    <div className={classNames("uk-width-1-2")}>
                                        <SuperbowlTile url={tiles?.[i]?.desktopImage} creativeId={tiles?.[i]?.creativeId}/>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>

                <div className={classNames(styles.tileContainerMobile, styles.elementContainer)}>
                    {
                        tiles.map((tile) => (
                            <div className={box.marginTop10}>
                                <SuperbowlTile url={tile.mobileImage} creativeId={tile.creativeId}/>
                            </div>
                        ))
                    }
                </div>

                {/*<div className={classNames(styles.container, styles.paragraphContainer)} dangerouslySetInnerHTML={{ __html: adRankingsSection?.content }} />*/}

                <div className={classNames(styles.buttonContainer)}>
                    <SignUpButton
                        title={"Get the latest insights from EDO in your inbox"}
                        subTitle={"Choose just the industries you care about"}
                        buttonTitle={"Sign Up Now"}
                        buttonStyle={{
                            background: "#109cf1",
                        }}
                        scrollStyle={{background: "#109cf1", color: "#ffffff", transition: "0.4s cubic-bezier(.33,.74,.83,1.08)"}}
                        scrollButtonStyle={{background: "#f26a5c", transition: "0.4s cubic-bezier(.33,.74,.83,1.08)"}}
                        href={"https://landing.edo.com/super-bowl-lvii"}
                    />
                </div>

                <div className={classNames(styles.container, styles.paragraphContainer)}>
                    <div className={classNames("uk-margin-top uk-margin-bottom uk-text-left", styles.subHeader)}>
                        <b>Super Bowl Ad Ranking</b>
                    </div>
                    {/* <div className={classNames(styles.paragraph)}>
                        <i>All ads aired during Super Bowl LVII ranked by Engagement Index, with the median performing in-game Super Bowl spot indexed to 100 in order to easily compare the relative impact of each ad on online search activity. The data included in this ranker is preliminary, and is subject to slight adjustments based on EDO's final Super Bowl LVII analysis.</i>
                    </div> */}
                </div>

                <div className={classNames(styles.container)}>
                    <div className={classNames("uk-flex uk-flex-right", styles.listHeader)}>
                        <b>Engagement Index</b>
                    </div>
                </div>
                <div className={styles.airingListContainer}>
                    { airings.map((airing, i) => {
                        return <AiringItem airing={airing} index={i + 1} key={i + 1}/>
                      })
                    }
                </div>

                {/*<div className={classNames(styles.container, styles.paragraphContainer)} dangerouslySetInnerHTML={{ __html: shareOfSearchSection?.content }} />*/}

                <div className={classNames(styles.buttonContainer, "uk-margin-top")}>
                  <ReportSignUpButton
                          buttonStyle={{
                              background: "#109cf1",
                          }}
                          scrollStyle={{background: "#109cf1", color: "#ffffff", transition: "0.4s cubic-bezier(.33,.74,.83,1.08)"}}
                          scrollButtonStyle={{background: "#f26a5c", transition: "0.4s cubic-bezier(.33,.74,.83,1.08)"}}
                      />
                </div>
            </div>



            {/* <div className={classNames(styles.container, styles.mainContainer, styles.footerContainer)}>
                <div className={styles.footerText}>
                    The Super Bowl is the big game, but brands fight for market share every day. EDO is here to help you get more from your national TV advertising year round.
                </div>
            </div> */}
        </div>
    </Layout>

  )
}

export const query = graphql`
  query resourcePostsAndMediaItemAds {
    allGoogleSpreadsheetAllSuperBowl2023(
      filter: { includeInSite: { eq: "1" } },
      sort: { fields: [rank], order: ASC }
    ) {
      edges {
        node {
          rank
          name
          description
          score
          quarter
          time
          duration
          creativeId
          includeInSite
        }
      }
    }
    allGoogleSpreadsheetTopSuperBowl2023(
      filter: { includeInSite: { eq: "1" } },
      sort: { fields: [rank], order: ASC }
    ) {
      edges {
        node {
          rank
          name
          desktopImage
          mobileImage
          creativeId
          includeInSite
        }
      }
    }
  }
`
// allRankings: wpMediaItem (
//   where: {id: $allRankings} 
// ) {
//   id
//   title
//   sourceUrl
// }
export default SuperBowlPage;
